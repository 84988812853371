body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiInputLabel-root {
  color: #212020 !important;
}

.datepicker {
  height: 40px;
}

@font-face {
  font-family: Roboto-Bold;
  src: url("../public/ui-assests/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: Roboto-Medium;
  src: url("../public/ui-assests/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: Roboto-Regular;
  src: url("../public/ui-assests/fonts/Roboto-Regular.ttf");
}

.fake-legend {
  height: 15px;
  width: 90%;
  border-bottom: solid 1px #dddddd;
  text-align: center;
}

.react-stopwatch-timer__element {
  width: 3px !important;
  height: 3px !important;
  border-radius: 5px;
}

.react-stopwatch-timer__table {
  width: 45px !important;
  height: 10px !important;
  color: #fff;
  font-size: 12px;
  font-family: 'Roboto-Bold';
  margin-top: '25px';
}


#bt.react-datepicker__input-container input {
  display: block;
  width: 100px;
  height: 20px;
  padding-left: 10px;
}

.fake-legend1 {
  height: 25px;
  width: 100%;
  border-bottom: solid 2px #C6F465;
  text-align: center;

}